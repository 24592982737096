/*
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2022-12-05 14:34:11
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-12-05 14:34:48
 */
import http from "./axios_init";
export default {
    /**
     * 获取全部的城市区域
     * @returns
     */
     get_areas() {
      return http.get("admin/v1/area");
    },
  };
  